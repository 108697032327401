import * as React from "react";
import {
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { connect } from "react-redux";
import {
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  MainInput,
  centered_flex_box,
  left_flex_box,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Button, Image, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getOrders, addReturn } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";
import { useState } from "react";

export const ReturnOrder = ({
  getOrders,
  user,
  order,
  addReturn,
  isLoading
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [orderItems, setOrderItems] = useState([]);
  const [refundAmount, setRefundAmount] = useState(0);

  useEffect(() => {
    getOrders({ orderId: id })
  }, [id])

  const handleSubmit = () => {
    addReturn({ orderId: id, orderItems, refundAmount })
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'externalId',
    },
    {
      title: 'Image',
      dataIndex: 'image',
      render: (text) => <ImageComponent width={50} height={50} src={text} preview={true} />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (text) => `${text} EGP`,
    },
    {
      title: 'Size',
      dataIndex: 'size',
      filters: [
        {
          text: "XXS",
          value: "XXS"
        },
        {
          text: "XS",
          value: "XS"
        },
        {
          text: "S",
          value: "S"
        },
        {
          text: "M",
          value: "M"
        },
        {
          text: "L",
          value: "L"
        },
        {
          text: "XL",
          value: "XL"
        },
        {
          text: "2XL",
          value: "2XL"
        },
        {
          text: "3XL",
          value: "3XL"
        }
      ],
      filterSearch: true,
      onFilter: (value, record) => record.size.startsWith(value),
    },
    {
      title: 'Color',
      dataIndex: 'color',
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
    },
    {
      title: 'Ordered',
      dataIndex: 'quantityOrdered',
      sorter: (a, b) => a.quantityOrdered - b.quantityOrdered,
    },
    {
      title: 'QTY to return',
      render: (_, { _id, quantityOrdered }) => <Space>
        <MainInput
          margin="normal"
          type="number"
          fullWidth
          id={_id}
          disabled={orderItems.filter((item) => item._id === _id).length === 0}
          inputProps={{
            min: 0,
            max: quantityOrdered
          }}
          value={orderItems.filter((item) => item._id === _id).length > 0 ? orderItems.filter((item) => item._id === _id)[0].quantity : 0}
          onChange={({ target }) => onQuantityChange(_id, target.value)}
        />
      </Space>
    },
  ];

  const data = order?.orderItems?.map((row) => {
    return {
      _id: row._id,
      key: row._id,
      externalId: row.stockItem.product.externalId,
      price: row.stockItem.product.price,
      image: row.stockItem.product.image,
      name: row.stockItem.product.name,
      size: row.stockItem.product.size,
      color: row.stockItem.product.color,
      weight: row.stockItem.product.weight,
      quantityOrdered: row.quantityOrdered
    }
  })

  const onQuantityChange = (_id, newQuantity) => {
    const newItems = orderItems.map((item) => {
      if (item._id === _id) {
        item.quantity = newQuantity;
      }
      return item
    });
    setOrderItems(newItems);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setOrderItems(newSelectedRowKeys.map((key) => { return { _id: key, quantity: 0 } }));
  };

  const rowSelection = {
    orderItems,
    onChange: onSelectChange,
  };

  const hasSelected = orderItems.length > 0;

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Order {id}</Typography>
          {/* <IconButton onClick={() => navigate("/ims/admin/products/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton> */}
        </Box>
        <Box sx={{}}>
          <Box sx={{ ...left_flex_box }}>
            <Space>
              <MainInput
                margin="normal"
                type="number"
                label="Refund Amount (EGP)"
                disabled={orderItems.length === 0}
                inputProps={{
                  min: 0
                }}
                value={refundAmount}
                onChange={({ target }) => setRefundAmount(target.value)}
              />
              <Button type="primary" onClick={handleSubmit} disabled={!hasSelected} loading={isLoading}>
                Send Return
              </Button>
              <Typography sx={{ ml: 1 }}>{hasSelected ? `Selected ${orderItems.length} items` : null}</Typography>
            </Space>
          </Box>
          <Table
            columns={columns}
            dataSource={data}
            showSorterTooltip={{
              target: 'sorter-icon',
            }}
            rowSelection={rowSelection}
          />
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  order: state?.records?.order
});

const mapDispatchToProps = { getOrders, addReturn };

export default connect(mapStateToProps, mapDispatchToProps)(ReturnOrder);
