import * as React from 'react';
import { Typography, Box, Container, Card, Button, TextField, CssBaseline, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, CardContent, Divider, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, left_flex_box, main_button, right_flex_box, sec_button } from '../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Image } from 'antd';
import { AdminPanelSettingsOutlined, ArrowForward, Dashboard, RequestPageOutlined, VisibilityOutlined } from '@mui/icons-material';
import { getDashboard } from '../app/store/actions/dataActions';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment/moment';
import DataCard from '../app/components/DataCard';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { useDrawingArea } from '@mui/x-charts';

export const Home = ({ user, getDashboard, dashboard }) => {

    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(moment().startOf("month"))
    const [endDate, setEndDate] = useState(moment().endOf("month"))

    useEffect(() => {
        getDashboard({ startDate, endDate });
    }, [])

    const handleFilter = () => {
        getDashboard({ startDate, endDate });
    }


    function PieCenterLabel({ children }) {
        const { width, height, left, top } = useDrawingArea();
        return (
            <Typography x={left + width / 2} y={top + height / 2}>
                {children}
            </Typography>
        );
    }

    return (
        <Container sx={{ minWidth: isMobile ? "100% !important" : "90% !important" }}>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Grid container p={5}>
                    <Grid item xs={isMobile ? 12 : 8}>
                        <Typography fontWeight={"bold"} variant={isMobile ? 'h4' : 'h2'}> Dashboard</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", px: 5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Typography>From</Typography>
                        <MainInput
                            margin="normal"
                            fullWidth
                            type="date"
                            value={startDate.format("YYYY-MM-DD")}
                            onChange={({ target }) => setStartDate(moment(target.value))}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Typography>To</Typography>
                        <MainInput
                            margin="normal"
                            fullWidth
                            type="date"
                            value={endDate.format("YYYY-MM-DD")}
                            onChange={({ target }) => setEndDate(moment(target.value))}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{ ...left_flex_box, alignItems: "end" }}>
                        <Button sx={{ ...main_button, mb: 2 }} onClick={() => handleFilter()}>Filter</Button>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={3} p={5}>
                <Grid item xs={isMobile ? 12 : 7}>
                    <DataCard title={"Received Orders"} content={`${dashboard?.orders.totalOrders} Orders`} hoverComponent={<Box width={"100%"} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <Typography mt={1} fontSize={14} fontWeight={"bold"}>Received Orders: {parseInt(dashboard?.orders.totalOrders)}</Typography>
                        <PieChart
                            height={400}
                            series={[
                                {
                                    data: [
                                        { id: 0, value: parseInt(dashboard?.orders.totalDelivered), label: 'Delivered Orders', color: "green" },
                                        { id: 1, value: parseInt(dashboard?.orders.totalFulfilled), label: 'Fulfilled Orders', color: "orange" },
                                        { id: 3, value: parseInt(dashboard?.orders.totalSoldOutOrders), label: 'Sold Out Orders', color: "gray" },
                                        { id: 4, value: parseInt(dashboard?.orders.totalEnRoute), label: 'Orders En Route', color: "blue" },
                                        { id: 5, value: parseInt(dashboard?.orders.totalCancelled), label: 'Cancelled Orders', color: "red" },
                                        { id: 6, value: parseInt(dashboard?.orders.totalReturned), label: 'Returned Orders' },
                                        { id: 7, value: parseInt(dashboard?.orders.totalOther), label: 'Other Orders'},
                                    ],
                                    innerRadius: 80,
                                    outerRadius: 120,
                                    paddingAngle: 5,
                                },
                            ]}
                        />
                    </Box>} />
                </Grid>
                <Grid item xs={isMobile ? 12 : 5}>
                    <DataCard title={"Net Income"} content={`${dashboard?.cod.netIncome} EGP`} hoverComponent={<Box width={"100%"} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <Typography mt={1} fontSize={14} fontWeight={"bold"}>Net Income: {parseInt(dashboard?.cod.netIncome)} EGP</Typography>
                        <BarChart
                            series={[
                                { data: [parseInt(dashboard?.cod.totalIncome)], label: 'COD', color: "blue" },
                                { data: [parseInt(dashboard?.cod.totalFees)], label: 'Fees', color: "red", stack: "intermediary" },
                                { data: [parseInt(dashboard?.cod.totalHold)], label: 'On Hold', color: "gray", stack: "intermediary" },
                                { data: [parseInt(dashboard?.cod.netIncome)], label: 'Net Income', color: "orange", stack: "intermediary" },
                                { data: [parseInt(dashboard?.cod.totalPayout)], label: 'Payout', color: "green" }
                            ]}
                            xAxis={[{ data: ["Values"], scaleType: 'band' }]}
                            height={400}
                        />
                    </Box>} />
                </Grid>
                <Grid item xs={12}>
                    <DataCard title={"Items in Stock"} content={`${dashboard?.stocks.totalStockItems} Items`} hoverComponent={<Box width={"100%"} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <Typography mt={1} fontSize={14} fontWeight={"bold"}>Items in Stock: {parseInt(dashboard?.stocks.totalStockItems)} Items</Typography>
                        <BarChart
                            series={[
                                { data: dashboard?.stocks.productsStock.map(({ value }) => { return value }), label: 'Items', color: "var(--secColor)" },
                            ]}
                            xAxis={[{ data: dashboard?.stocks.productsStock.map(({ label }) => { return label }), scaleType: 'band' }]}
                            height={400}
                        />
                    </Box>} />
                </Grid>
            </Grid>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    dashboard: state?.records?.dashboard
});

const mapDispatchToProps = { getDashboard };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
