import * as React from "react";
import {
    Typography,
    Box,
    IconButton,
} from "@mui/material";
import { connect } from "react-redux";
import {
    useNavigate,
} from "react-router-dom";
import {
    centered_flex_box,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getInbounds } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";

export const Inbounds = ({
    getInbounds,
    user,
    inbounds,
    isLoading
}) => {
    const navigate = useNavigate();
    const [error, setError] = React.useState(null);
    const [terms, setTerms] = React.useState(false);

    useEffect(() => {
        getInbounds({ page: 1, limit: 500, status: "any" })
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: '_id',
        },
        {
            title: 'Date Created',
            dataIndex: 'dateCreated',
            defaultSortOrder: 'descend',
            sorter: (a, b) => { return moment(a.dateCreated).isBefore(b.dateCreated) ? -1 : 1 },
        },
        {
            title: 'Destination',
            dataIndex: 'targetWarehouse'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filters: [
                {
                    text: "Pending",
                    value: "Pending"
                },
                {
                    text: "Receiving",
                    value: "Receiving"
                },
                {
                    text: "Partially Received",
                    value: "Partially Received"
                },
                {
                    text: "Received",
                    value: "Received"
                },
                {
                    text: "Cancelled",
                    value: "Cancelled"
                }
            ],
            filterSearch: true,
            onFilter: (value, record) => record.status.startsWith(value),
            render: (_, { status }) => <Tag color={status === "Cancelled" ? "red" : status === "Received" ? "green" : "yellow"}>{status.toUpperCase()}</Tag>
        },
        {
            title: 'Total Pieces',
            dataIndex: 'pieces',
        },
    ];

    const expandedRowRender = (items) => {
        console.log(items)
        const columns = [
            {
                title: 'Product ID',
                dataIndex: 'inboundId',
            },
            {
                title: 'Product SKU',
                dataIndex: 'sku',
            },
            {
                title: 'Image',
                dataIndex: 'image',
                render: (text) => <Image width={50} height={50} src={text} preview={true} />,
            },
            {
                title: 'Name',
                dataIndex: 'name'
            },
            {
                title: 'Size',
                dataIndex: 'size',
            },
            {
                title: 'Color',
                dataIndex: 'color',
            },
            {
                title: 'Weight',
                dataIndex: 'weight',
            },
            {
                title: 'QTY Sent',
                dataIndex: 'quantity',
            },
            {
                title: 'QTY Received',
                dataIndex: 'quantityReceived',
            },
            {
                title: 'QTY Remaining',
                render: (_, { quantity, quantityReceived }) => parseInt(quantity) - parseInt(quantityReceived),
            },
        ];

        const data = items?.map((row) => {
            return {
                _id: row._id,
                inboundId: row._id,
                sku: row.product.sku,
                image: row.product.image,
                name: row.product.name,
                size: row.product.size,
                color: row.product.color,
                weight: row.product.weight,
                quantity: row.quantity,
                quantityReceived: row.quantityReceived,
            }
        })
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    const data = inbounds?.docs?.map((row) => {
        return {
            _id: row._id,
            dateCreated: moment(row.createdAt).format("lll"),
            targetWarehouse: row.direction === "Out" ? "Out of Chainz" : row.targetWarehouse.name,
            status: row.status,
            items: row.items,
            pieces: row.items.reduce((a, b) => a + b.quantity, 0)
        }
    })

    return (
        <div>
            <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
                <Box sx={{ ...centered_flex_box, mb: 1 }}>
                    <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Inventory History</Typography>
                    <IconButton onClick={() => navigate("/inventory/send")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton>
                </Box>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={isLoading}
                    rowKey={"_id"}
                    expandable={{
                        expandedRowRender: (record) => expandedRowRender(record.items)
                    }}
                    showSorterTooltip={{
                        target: 'sorter-icon',
                    }}
                />
            </Box>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    token: state?.auth?.token,
    inbounds: state?.records?.inbounds
});

const mapDispatchToProps = { getInbounds };

export default connect(mapStateToProps, mapDispatchToProps)(Inbounds);
