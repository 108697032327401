import * as React from "react";
import {
  Typography,
  Box,
  IconButton,
  Grid,
  Button as MUIButton
} from "@mui/material";
import { connect } from "react-redux";
import {
  NavLink,
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  MainInput,
  centered_flex_box,
  left_flex_box,
  main_button,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Button, Image, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getTransactions } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";

export const Transactions = ({
  getTransactions,
  user,
  transactions,
  payouts,
  isLoading
}) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = React.useState(moment().startOf("month"))
  const [endDate, setEndDate] = React.useState(moment().endOf("month"))

  useEffect(() => {
    getTransactions({ startDate, endDate })
  }, [])

  const handleFilter = () => {
    getTransactions({ startDate, endDate });
  }

  const columns = [
    {
      title: 'Order ID',
      dataIndex: '_id',
    },
    {
      title: 'Order Tracking ID',
      dataIndex: ['order', 'shipbluTrackingId'],
    },
    {
      title: 'Status',
      dataIndex: ['order', 'status'],
      filters: [
        {
          text: "New",
          value: "New"
        },
        {
          text: "Preparing",
          value: "Preparing"
        },
        {
          text: "Prepared",
          value: "Prepared"
        },
        {
          text: "Out for Delivery",
          value: "Out for Delivery"
        },
        {
          text: "Delivered",
          value: "Delivered"
        },
        {
          text: "Cancelled",
          value: "Cancelled"
        },
        {
          text: "Sold Out",
          value: "Sold Out"
        },
        {
          text: "Invalid",
          value: "Invalid"
        }
      ],
      onFilter: (value, record) => record.order.status === value,
      render: (_, { order }) => <Tag color={order.status === "Delivered" ? "green" : order.status === "Cancelled" ? "red" : order.status === "Prepared" ? "blue" : "yellow"}>{order.status.toUpperCase()}</Tag>
    },
    {
      title: 'Actions',
      render: (_, { order }) => <Space>
        <Button onClick={() => navigate(`/orders/${order._id}`)}>View Order</Button>
      </Space>
    },
  ];

  const data = transactions?.map((row) => {
    return {
      _id: row.order._id,
      order: row.order,
      transactions: row.transactions,
    }
  })

  const expandedRowRender = (items) => {
    const columns = [
      {
        title: 'Date Created',
        dataIndex: 'date',
        sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
      },
      {
        title: 'Value',
        dataIndex: 'value',
        render: (_, { value }) => `${value} EGP`,
        sorter: (a, b) => { return a.value - b.value },
      },
      {
        title: 'Type',
        dataIndex: 'type',
        filters: [
          {
            text: "COD",
            value: "COD"
          },
          {
            text: "Service Fee",
            value: "Fee"
          },
          {
            text: "Shipping Fee",
            value: "ShippingFee"
          },
          {
            text: "Hold",
            value: "Hold"
          },
          {
            text: "Payout",
            value: "Payout"
          }
        ],
        onFilter: (value, record) => record.type === value,
        render: (_, { type }) => <Tag>{type}</Tag>
      },
      {
        title: 'Service',
        dataIndex: 'service',
        filters: [
          {
            text: "RTO",
            value: "RTO"
          },
          {
            text: "RTN",
            value: "RTN"
          },
          {
            text: "EXG",
            value: "EXG"
          },
          {
            text: "DLV",
            value: "DLV"
          },
          {
            text: "TRN",
            value: "TRN"
          },
          {
            text: "NONE",
            value: "NONE"
          }
        ],
        onFilter: (value, record) => record.service === value,
        render: (_, { service }) => <Tag>{service}</Tag>
      },
    ];

    const data = items?.map((row) => {
      return {
        _id: row._id,
        date: moment(row.createdAt).format("lll"),
        value: row.value,
        type: row.type,
        service: row.service,
      }
    })
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const payoutColumns = [
    {
      title: 'Date Paid',
      dataIndex: 'date',
      sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (_, { value }) => `${value} EGP`,
      sorter: (a, b) => { return a.value - b.value },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: [
        {
          text: "Service Fee",
          value: "Fee"
        },
        {
          text: "Payout",
          value: "Payout"
        }
      ],
      onFilter: (value, record) => record.type === value,
      render: (_, { type }) => <Tag>{type}</Tag>
    },
    {
      title: 'Service',
      dataIndex: 'service',
      render: (_, { service }) => <Tag>{service}</Tag>
    },
  ];

  const payoutData = payouts?.map((row) => {
    return {
      _id: row._id,
      date: moment(row.createdAt).format("lll"),
      service: row.service,
      type: row.type,
      value: row.value
    }
  })

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Transactions</Typography>
          {/* <IconButton onClick={() => navigate("/ims/admin/products/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton> */}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", px: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography>From</Typography>
              <MainInput
                margin="normal"
                fullWidth
                type="date"
                value={startDate.format("YYYY-MM-DD")}
                onChange={({ target }) => setStartDate(moment(target.value))}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography>To</Typography>
              <MainInput
                margin="normal"
                fullWidth
                type="date"
                value={endDate.format("YYYY-MM-DD")}
                onChange={({ target }) => setEndDate(moment(target.value))}
              />
            </Grid>
            <Grid item xs={2} sx={{ ...left_flex_box, alignItems: "end" }}>
              <MUIButton sx={{ ...main_button, mb: 2 }} onClick={() => handleFilter()}>Filter</MUIButton>
            </Grid>
          </Grid>
        </Box>
        <Typography variant='h5' sx={{ color: "var(--secColor)", mt: 5 }}>All Order Transactions</Typography>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
          rowKey={"_id"}
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record.transactions)
          }}
        />
        <Typography variant='h5' sx={{ color: "var(--secColor)", mt: 5 }}>Payouts</Typography>
        <Table
          columns={payoutColumns}
          dataSource={payoutData}
          loading={isLoading}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  transactions: state?.records?.transactions,
  payouts: state?.records?.payouts
});

const mapDispatchToProps = { getTransactions };

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
