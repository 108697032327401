import * as React from "react";
import {
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { connect } from "react-redux";
import {
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  centered_flex_box,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Button, Image, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getExchanges, getOrders } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";

export const Exchanges = ({
  getExchanges,
  user,
  exchanges,
  isLoading
}) => {
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [terms, setTerms] = React.useState(false);

  useEffect(() => {
    getExchanges({ page: 1, limit: 500 })
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
    },
    {
      title: 'Date Created',
      dataIndex: 'date',
      sorter: (a, b) => {return moment(a.date).isBefore(b.date) ? -1 : 1},
    },
    {
      title: 'Date Fulfilled',
      dataIndex: 'dateFulfilled',
      sorter: (a, b) => {return moment(a.dateFulfilled).isBefore(b.dateFulfilled) ? -1 : 1},
    },
    {
      title: 'Date Delivered',
      dataIndex: 'dateDelivered',
      sorter: (a, b) => {return moment(a.dateDelivered).isBefore(b.dateDelivered) ? -1 : 1},
    },
    {
      title: 'Customer Name',
      dataIndex: 'name',
      filters: exchanges?.docs?.filter((exchange) => exchange.customer !== null).map((exchange) => {return {
        text: exchange.customer?.name,
        value: exchange.customer?.name
      }}),
      filterSearch: true,
      onFilter: (value, record) => record.customer.startsWith(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: "New",
          value: "New"
        },
        {
          text: "Preparing",
          value: "Preparing"
        },
        {
          text: "Prepared",
          value: "Prepared"
        },
        {
          text: "Out for Delivery",
          value: "Out for Delivery"
        },
        {
          text: "Delivered",
          value: "Delivered"
        },
        {
          text: "Returned",
          value: "Returned"
        },
        {
          text: "Cancelled",
          value: "Cancelled"
        },
        {
          text: "Sold Out",
          value: "Sold Out"
        },
        {
          text: "Invalid",
          value: "Invalid"
        }
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, {status}) => <Tag color={(status === "Delivered" || status === "Returned") ? "green" : status === "Cancelled" ? "red" : status === "Prepared" ? "blue" : "yellow"}>{status.toUpperCase()}</Tag>
    },
    {
      title: 'Actions',
      render: (_, {_id, originalOrder, incomingOrder, outgoingOrder}) => <Space>
        <Button onClick={() => navigate(`/orders/${originalOrder._id}`)}>View Original Order</Button>
        <Button onClick={() => navigate(`/orders/${outgoingOrder._id}`)}>View Outgoing Order</Button>
        <Button onClick={() => navigate(`/orders/${incomingOrder._id}`)}>View Incoming Order</Button>
      </Space>
    },
  ];

  const data = exchanges?.docs?.map((row) => {
    return {
      _id: row._id,
      date: moment(row.createdAt).format("lll"),
      dateFulfilled: row.outgoingOrder.preparedAt ? moment(row.outgoingOrder.preparedAt).format("lll") : null,
      dateDelivered: row.outgoingOrder.deliveredAt ? moment(row.outgoingOrder.deliveredAt).format("lll") : null,
      status:  row.outgoingOrder.status,
      customer: row.customer,
      name: row.customer.name,
    }
  })

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Exchanges</Typography>
          {/* <IconButton onClick={() => navigate("/ims/admin/products/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton> */}
        </Box>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  exchanges: state?.records?.exchanges
});

const mapDispatchToProps = { getExchanges };

export default connect(mapStateToProps, mapDispatchToProps)(Exchanges);
