import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, CHANGE, CHANGE_SUCCESS, CHANGE_FAIL, CART, CART_SUCCESS, CART_FAIL, WALLET, WALLET_SUCCESS, WALLET_FAIL, WAIT, WAIT_SUCCESS, WAIT_FAIL } from "./types";
import { getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";

export const loadUser = () => async (dispatch, getState) => {
  dispatch({
    type: LOGIN,
  });
  let user = localStorage.getItem("ChainzIMSPartnerUser");

  if (user) {
    user = JSON.parse(user);
    return dispatch({
      type: LOGIN_SUCCESS,
      payload: user,
      token: localStorage.getItem("ChainzIMSPartnerToken")
    });
  } else {
    return dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const logout = () => async (dispatch, getState) => {
  return dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const login = (data) => (dispatch) => {
  dispatch({ type: LOGIN });

  postRequest(data, undefined, undefined, undefined, endpoints.auth.login)
    .then((response) => {
      if (response.data.message === "Success") {
        notification.success({ message: "Welcome Back", style: { marginTop: "10vh" } })
      } else {
        notification.error({ message: response.data.message, style: { marginTop: "10vh" } })
      }
      const { data } = response;
      console.log(data)
      return dispatch({
        type: LOGIN_SUCCESS,
        payload: data.payload,
        token: data.token
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: LOGIN_FAIL,
      });
    });
};

export const changePassword = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: CHANGE });
  const token = useState().auth?.token
  const role = token.split(" ")[0]
  let end
  switch(role){
    case "InventoryAdmin": end = endpoints.auth.password
  }

  putRequest(data, undefined, undefined, token, end)
    .then((response) => {
      const { data } = response;
      notification.success({ message: "Password Changed Successfully", style: { marginTop: "10vh" } })
      return dispatch({
        type: CHANGE_SUCCESS,
        payload: data.payload,
        token: data.token
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: CHANGE_FAIL,
      });
    });
};

export const forgotPassword = (data, navigate) => (dispatch) => {
  dispatch({
    type: WAIT,
  })
  postRequest(data, undefined, undefined, undefined, endpoints.auth.forgotPassword)
    .then((response) => {
      const { data } = response;
      navigate && navigate("/login");
      return dispatch({
        type: WAIT_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: WAIT_FAIL,
      });
    });
}

export const resetPassword = (data, navigate) => (dispatch) => {
  dispatch({
    type: WAIT,
  })
  postRequest(data, undefined, undefined, undefined, endpoints.auth.resetPassword)
    .then((response) => {
      const { data } = response;
      navigate && navigate("/login");
      return dispatch({
        type: WAIT_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      notification.error({ message: err?.response?.data?.message, style: { marginTop: "10vh" } })
      console.log(err);
      return dispatch({
        type: WAIT_FAIL,
      });
    });
}