import * as React from "react";
import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Card,
    CardContent,
    IconButton
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sec_button } from "./Styles";
import { PushPin, PushPinOutlined } from "@mui/icons-material";

function DataCard({ title, content, hoverComponent }) {

    const [hovered, setHovered] = React.useState(false)
    const [pin, setPin] = React.useState(false)

    return (
        <Card onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false || pin)} m={3}>
            {!hovered && <>
                <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography fontSize={14} fontWeight={"bold"}>{title}</Typography>
                </CardContent>
                <CardContent>
                    <Typography fontSize={18}>{content}</Typography>
                </CardContent>
            </>}
            {hovered && <CardContent>
                {pin ? <IconButton onClick={() => setPin(false)}><PushPin/></IconButton> : <IconButton onClick={() => setPin(true)}><PushPinOutlined/></IconButton>}
                {hoverComponent}
            </CardContent>}
        </Card>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DataCard);
