import * as React from "react";
import {
    Typography,
    Box,
    IconButton,
} from "@mui/material";
import { connect } from "react-redux";
import {
    useNavigate,
} from "react-router-dom";
import {
    centered_flex_box,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, Table } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getProducts } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";

export const Products = ({
    getProducts,
    user,
    products,
    isLoading
}) => {
    const navigate = useNavigate();
    const [error, setError] = React.useState(null);
    const [terms, setTerms] = React.useState(false);

    useEffect(() => {
        getProducts({ page: 1, limit: 500 })
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'externalId',
        },
        {
            title: 'Image',
            dataIndex: 'image',
            render: (text) => <ImageComponent width={50} height={50} src={text} preview={true} />,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            filters: products?.docs?.map((product) => {
                return {
                    text: product.name,
                    value: product.name
                }
            }),
            filterSearch: true,
            onFilter: (value, record) => record.name.startsWith(value),
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: 'Size',
            dataIndex: 'size',
            filters: [
                {
                    text: "XXS",
                    value: "XXS"
                },
                {
                    text: "XS",
                    value: "XS"
                },
                {
                    text: "S",
                    value: "S"
                },
                {
                    text: "M",
                    value: "M"
                },
                {
                    text: "L",
                    value: "L"
                },
                {
                    text: "XL",
                    value: "XL"
                },
                {
                    text: "2XL",
                    value: "2XL"
                },
                {
                    text: "3XL",
                    value: "3XL"
                }
            ],
            filterSearch: true,
            onFilter: (value, record) => record.size.startsWith(value),
        },
        {
            title: 'Color',
            dataIndex: 'color',
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            sorter: (a, b) => a.price - b.price,
            render: (_, { price }) => `${price} EGP`
        },
        {
            title: 'In Transfer',
            dataIndex: 'inbound',
            sorter: (a, b) => a.inbound - b.inbound,
        },
        {
            title: 'QTY on Hand',
            dataIndex: 'amount',
            sorter: (a, b) => a.amount - b.amount,
        },
        {
            title: 'Faulty',
            dataIndex: 'returns',
            sorter: (a, b) => a.returns - b.returns,
        },
    ];

    const data = products?.docs?.map((row) => {
        return {
            _id: row._id,
            externalId: row.sku || row.externalId,
            image: row.image,
            name: row.name,
            size: row.size,
            color: row.color,
            weight: row.weight,
            price: row.price,
            inbound: row.inbounds?.reduce((a, b) => a + (b.quantity - b.quantityReceived), 0),
            amount: row.stocks?.reduce((a, b) => a + b.amount, 0),
            returns: row.returns?.reduce((a, b) => a + b.amount, 0),
        }
    })

    return (
        <div>
            <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
                <Box sx={{ ...centered_flex_box, mb: 1 }}>
                    <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Products</Typography>
                    {/* <IconButton onClick={() => navigate("/ims/admin/products/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton> */}
                </Box>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={isLoading}
                    showSorterTooltip={{
                        target: 'sorter-icon',
                    }}
                />
            </Box>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    token: state?.auth?.token,
    products: state?.records?.products
});

const mapDispatchToProps = { getProducts };

export default connect(mapStateToProps, mapDispatchToProps)(Products);
