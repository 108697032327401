import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { CreateInbound, Exchanges, Home, Inbounds, Keys, LoginPartner, Order, Orders, Products, ReturnOrder, Returns, Transactions } from "./Pages";
import ProtectedRoute from "./ProtectedRoute";

export const Router = () => {
  return (
    <Routes>
      <Route path="/login" exact element={<LoginPartner />} />
      <Route exact element={<ProtectedRoute allowed={["Partner"]} />}>
        <Route path="/" exact element={<Home />} />
        <Route path="/products" exact element={<Products />} />
        <Route path="/inventory/send" exact element={<CreateInbound />} />
        <Route path="/inventory/history" exact element={<Inbounds />} />
        <Route path="/keys" exact element={<Keys />} />
        <Route path="/returns" exact element={<Returns />} />
        <Route path="/exchanges" exact element={<Exchanges />} />
        <Route path="/orders" exact element={<Orders />} />
        <Route path="/orders/:id" exact element={<Order />} />
        <Route path="/orders/return/:id" exact element={<ReturnOrder />} />
        <Route path="/finance" exact element={<Transactions />} />
      </Route>
    </Routes>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
